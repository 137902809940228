<template>
  <div>
    <v-container>
      <v-row class="mb-4">
        <h2>Title Page</h2>
      </v-row>

      <v-simple-table>
        <thead>
          <tr v-for="field in fields" :key="field">
            <td style="width: 220px">
              {{ field.name }}
            </td>
            <td>
              <v-text-field v-model="inputs[field.inputKey]" />
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </v-container>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'UTSAChemTitlePage',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        title: null,
        name: null,
        snumber: null,
        pnumber: null,
        date: null,
      },
      fields: [
        {name: 'Experiment Title: ', inputKey: 'title'},
        {name: 'Student Name: ', inputKey: 'name'},
        {name: 'Student Number: ', inputKey: 'snumber'},
        {name: 'Practical Number: ', inputKey: 'pnumber'},
        {name: 'Experiment Date: ', inputKey: 'date'},
      ],
    };
  },
};
</script>

<style scoped></style>
